.Nav  {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 2em;
  font-size: 1.25rem;
}


.Nav button {
  font-family: "Chicago",-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: transparent;
  border: 0;
  color: #fff;
  text-decoration: none;
  margin-right: 2rem;
  text-transform: lowercase;
}

.Nav-social {
  list-style: none;
  display: flex;
  margin-right: 1rem
}

.Nav-social li {
  margin-left: .5rem;
}

.Nav-social li a{
  color: #fff;
  transition: color ease-in-out 0.2s;
}

.Nav-social li a:hover{
  color: var(--dark);
}


.Nav-icon {
  color: #fff;
  padding: 0 2rem 0 1rem;
}

@media (max-width: 600px) {
  .Nav .time {
    display: none;
  }
}