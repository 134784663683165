.theme-preview {
  width: 100%;
  height: 90%;
  border: 1px solid var(--black);
  background-size: 500%;
  background-position: center;
}

.theme-btn {
  background: transparent;
  border: 1px solid var(--black);
  margin-top: 0.5rem;
  border-radius: 5px;
  width: 100%;
  font-size: 14px;
}

.action-btns {
  display: flex;
  gap: 5px;
}

.theme-action {
  flex: 1;
}