.LixyOS {
  background-image: url('https://lixy-portfolio.s3.us-west-1.amazonaws.com/background/grid.png');
  background-size: cover;
  letter-spacing: 1px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  background-position: center center;
}
.LixyOS.dark-mode {
  background-blend-mode: soft-light;
}

.Window-container {
  width: 100%;
  height: calc(100% - 38px);
  overflow: hidden;
}
