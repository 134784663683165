.bio {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
}

.bio-img {
  max-width: 9rem;
  max-height: 9rem;
  border-radius: 50%;
  margin-right: 2em;
  border: 2px solid var(--white);
  box-shadow: 0 0 0px 1px var(--black);
}

.bio-info p{
  text-align: left;
}

.bio-info a {
  text-decoration: underline;
  color: var(--dark);
}


@media (max-width: 600px) {
  .bio {
    flex-direction: column;
  }
}